// src/index.ts
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  AspectRatio,
  Box as Box2,
  Button,
  ButtonGroup,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Center,
  ChakraProvider,
  Checkbox,
  CheckboxGroup,
  CircularProgress,
  CircularProgressLabel,
  CloseButton,
  Container,
  DarkMode,
  Divider,
  extendTheme as extendTheme2,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Hide,
  HStack,
  Icon,
  IconButton,
  Input,
  Image,
  Kbd,
  LightMode,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  Portal,
  Progress,
  Show,
  SimpleGrid,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spinner,
  Spacer,
  Stack,
  Switch,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  VStack
} from "@chakra-ui/react";

// src/components/Card.tsx
import React from "react";
import {
  Box,
  useStyleConfig
} from "@chakra-ui/react";
function Card(props) {
  const { variant, ...rest } = props;
  const styles = useStyleConfig("Card", { variant });
  return /* @__PURE__ */ React.createElement(Box, {
    __css: styles,
    ...rest
  });
}
var CardTheme = {
  baseStyle: ({ theme: theme2 }) => ({
    isolation: "isolate",
    position: "relative",
    borderRadius: `clamp(0px, calc((100vw - 4px - 100%) * 9999), ${theme2.radii.md})`
  }),
  variants: {
    default: ({ colorMode, theme: theme2 }) => ({
      bgColor: colorMode === "dark" ? "gray.700" : "white",
      color: colorMode === "dark" ? "white" : "gray.700",
      boxShadow: "md"
    }),
    outline: {
      border: "1px solid",
      borderColor: "gray.200"
    },
    acrylic: ({ colorMode }) => ({
      bgColor: colorMode === "dark" ? "blackAlpha.800" : "whiteAlpha.800",
      color: colorMode === "dark" ? "gray.200" : "gray.800",
      border: "1px solid",
      borderColor: colorMode === "dark" ? "whiteAlpha.400" : "whiteAlpha.700",
      backdropFilter: "auto",
      backdropBlur: "20px",
      backdropSaturate: "500%",
      backdropContrast: "50%",
      boxShadow: "md"
    })
  },
  defaultProps: {
    variant: "default"
  }
};

// src/theme.ts
import { extendTheme } from "@chakra-ui/react";
var theme = extendTheme({
  fonts: {
    heading: "-apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'segoe ui', 'helvetica neue', helvetica, Ubuntu, roboto, noto, arial, sans-serif",
    body: "-apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'segoe ui', 'helvetica neue', helvetica, Ubuntu, roboto, noto, arial, sans-serif",
    mono: "SFMono-Regular,Menlo,Monaco,Consolas,'Liberation Mono','Courier New',monospace"
  },
  shadows: {
    outline: "0 0 0 3px var(--chakra-colors-primary-400)"
  },
  components: {
    Card: CardTheme
  }
});
export {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  AspectRatio,
  Box2 as Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Card,
  Center,
  Checkbox,
  CheckboxGroup,
  CircularProgress,
  CircularProgressLabel,
  CloseButton,
  Container,
  DarkMode,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Heading,
  Hide,
  Icon,
  IconButton,
  Image,
  Input,
  Kbd,
  LightMode,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Progress,
  Show,
  SimpleGrid,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spacer,
  Spinner,
  Stack,
  Switch,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  ChakraProvider as ThemeProvider,
  Tooltip,
  Tr,
  VStack,
  extendTheme2 as extendTheme,
  theme,
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
  useDisclosure
};
